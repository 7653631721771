%btn {
	display: inline-block;
	white-space: nowrap;
	cursor: pointer;
	user-select: none;
}

// button,
// a,
// [class *= "button"],
// [class *= "btn"],
// [class *= "link"],
// [type *= "submit"] {
// 	cursor: pointer;
// }


.btn {
	$b: &;

	@extend %btn;
	@extend %text-mid;

	padding: 0 64px;
	height: 58px;
	line-height: 58px;
	text-align: center;
	background-color: $c-accent;
	transition: background-color .3s;
	border-radius: 8px;

	@include lg {
		
	}
	@include md {
		
	}
	@include sm {
		padding: 0 48px;
		height: 50px;
		line-height: 50px;
	}
	@include xs {
		width: 100%;
	}

	&:hover {
		background-color: rgba($c-accent, .8);
	}
}

.btn-i {
	@extend .btn;

	display: inline-flex;
	align-items: center;
	justify-content: center;

	span {
		position: relative;
		display: block;
		transition: transform .3s;
	}
	&:hover {
		span {
			transform: translateX(0.75em);
		}
		svg {
			opacity: 1;
		}
	}

	svg {
		position: absolute;
		top: 50%;
		right: 100%;
		display: block;
		width: 1.2em;
		max-height: 1.5em;
		margin-right: 0.3em;
		transform: translateY(-50%);
		opacity: 0;
		transition: opacity .3s;

		path {
			stroke: currentColor;
		}

		// .btn-i:hover & {
		// 	opacity: 1;
		// }
	}

	// &__icon {
		
	// }
}