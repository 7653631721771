*, *::before, *::after { box-sizing: border-box; }

html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, embed, figure, figcaption, footer, header, hgroup, menu, nav, output, ruby, section, summary, time, mark, audio, video { padding: 0; margin: 0; font: inherit; font-size: 100%; vertical-align: baseline; border: 0; outline: none; -webkit-tap-highlight-color: transparent; }

article, aside, details, figcaption, figure, footer, header, hgroup, menu, nav, section { display: block; }

body { line-height: 1; -webkit-font-smoothing: antialiased; -moz-osx-font-smoothing: grayscale; }

ol, ul { list-style: none; }

blockquote, q { quotes: none; }

blockquote::before, blockquote::after { content: ""; content: none; }

q::before, q::after { content: ""; content: none; }

table { border-spacing: 0; border-collapse: collapse; }

button { width: auto; padding: 0; margin: 0; overflow: visible; font: inherit; /* Normalize `line-height`. Cannot be changed from `normal` in Firefox 4+. */ line-height: normal; /* inherit font & color from ancestor */ color: inherit; background: transparent; border: none; outline: none; /* Corrects font smoothing for webkit */ -webkit-font-smoothing: inherit; -moz-osx-font-smoothing: inherit; /* Corrects inability to style clickable `input` types in iOS */ appearance: none; /* Remove excess padding and border in Firefox 4+ */ }

button::-moz-focus-inner { padding: 0; border: 0; }

a { color: inherit; text-decoration: none; }

input, select, textarea, label { display: inline-block; padding: 0; margin: 0; font-family: inherit; font-size: inherit; line-height: inherit; white-space: normal; vertical-align: middle; background: none; border: 0; border: none; border-radius: 0; box-shadow: none; box-shadow: none; box-shadow: none; appearance: none; }

input::placeholder, select::placeholder, textarea::placeholder, label::placeholder { font-family: inherit; font-size: inherit; color: inherit; }

input:focus, select:focus, textarea:focus, label:focus { outline: 0; }

@font-face { font-family: "Prata"; font-style: normal; font-weight: 400; src: url("../fonts/Prata-Regular.woff2") format("woff2"), url("../fonts/Prata-Regular.woff") format("woff"); font-display: swap; }

@font-face { font-family: "NotoSans"; font-style: normal; font-weight: 400; src: url("../fonts/NotoSans-Regular.woff2") format("woff2"), url("../fonts/NotoSans-Regular.woff") format("woff"); font-display: swap; }

@font-face { font-family: "NotoSans"; font-style: italic; font-weight: 400; src: url("../fonts/NotoSans-Italic.woff2") format("woff2"), url("../fonts/NotoSans-Italic.woff") format("woff"); font-display: swap; }

/* This mixin can be used to set the object-fit: @include object-fit(contain); or object-fit and object-position: @include object-fit(cover, top); */
@keyframes fadeIn { 0% { opacity: 0; }
  100% { opacity: 1; } }

@keyframes ripple { 0% { opacity: .7;
    transform: scale(1); }
  100% { opacity: 0.6;
    transform: scale(1.4); } }

@keyframes spin { 0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); } }

@keyframes ripple--center { 0% { opacity: .7;
    transform: scale(1) translate(-50%, -50%);
    transform-origin: 50% 50%; }
  100% { opacity: 0.6;
    transform: scale(2.1) translate(-50%, -50%);
    transform-origin: 50% 50%; } }

@keyframes ripple--center-delay { 0% { opacity: 0;
    transform: translate(-50%, -50%) scale(1); }
  1% { opacity: .3;
    transform: translate(-50%, -50%) scale(1); }
  80% { opacity: 0.03;
    transform: translate(-50%, -50%) scale(1.9); }
  85% { opacity: 0;
    transform: translate(-50%, -50%) scale(1.9); }
  100% { opacity: 0;
    transform: translate(-50%, -50%) scale(2.1); } }

[data-ae], [data-st], [data-fade] { opacity: 0; will-change: opacity; }

.container { width: 100%; max-width: calc(100% - 96px); margin: 0 auto; }

@media (max-width: 1511px) { .container { max-width: calc(100% - 64px); } }

@media (max-width: 1199px) { .container { max-width: calc(100% - 48px); } }

@media (max-width: 1023px) { .container { max-width: calc(100% - 64px); } }

@media (max-width: 767px) { .container { max-width: calc(100% - 40px); } }

.row { --bs-gutter-x: 30px; --bs-gutter-y: 0; display: flex; flex-wrap: wrap; margin-top: calc(var(--bs-gutter-y) * -1); margin-right: calc(var(--bs-gutter-x) / -2); margin-left: calc(var(--bs-gutter-x) / -2); }

html { font-family: "NotoSans", helvetica, tahoma, arial, sans-serif; line-height: 1.2; scroll-behavior: smooth; }

body ::-moz-selection { color: #fff; background: #FEB032; }

body ::selection { color: #fff; background: #FEB032; }

.usp, .pgrid, .product, .reviews { padding: 80px 0; }

@media (max-width: 1511px) { .usp, .pgrid, .product, .reviews { padding: 72px 0; } }

@media (max-width: 767px) { .usp, .pgrid, .product, .reviews { padding: 40px 0; } }

.hero__title { font-family: "Prata", Georgia, Garamond, "Times New Roman", Times, serif; font-size: 60px; }

@media (max-width: 1199px) { .hero__title { font-size: 48px; } }

@media (max-width: 1023px) { .hero__title { font-size: 36px; } }

.section-title, .product__title, .product__title-overlay { font-family: "Prata", Georgia, Garamond, "Times New Roman", Times, serif; font-size: 48px; }

@media (max-width: 1199px) { .section-title, .product__title, .product__title-overlay { font-size: 40px; } }

@media (max-width: 1023px) { .section-title, .product__title, .product__title-overlay { font-size: 40px; } }

@media (max-width: 767px) { .section-title, .product__title, .product__title-overlay { font-size: 36px; } }

.footer__col-title, .modal__title { font-family: "Prata", Georgia, Garamond, "Times New Roman", Times, serif; font-size: 24px; }

@media (max-width: 1023px) { .footer__col-title, .modal__title { font-size: 20px; } }

.btn, .btn-i, .form__button, .product-card__title, .product__text, .reviews__card-text { font-size: 24px; line-height: 1.2; }

@media (max-width: 1511px) { .btn, .btn-i, .form__button, .product-card__title, .product__text, .reviews__card-text { font-size: 22px; } }

@media (max-width: 1023px) { .btn, .btn-i, .form__button, .product-card__title, .product__text, .reviews__card-text { font-size: 20px; } }

@media (max-width: 767px) { .btn, .btn-i, .form__button, .product-card__title, .product__text, .reviews__card-text { font-size: 18px; } }

.usp__item-text, .product-card__price, .modal__thank, .hero__text { font-size: 20px; line-height: 1.2; }

@media (max-width: 767px) { .usp__item-text, .product-card__price, .modal__thank, .hero__text { font-size: 18px; } }

.reviews__card-name { font-size: 16px; }

@media (max-width: 767px) { .reviews__card-name { font-size: 14px; } }

.section-title--center { text-align: center; }

@keyframes fadeIn { 0% { opacity: 0; }
  100% { opacity: 1; } }

@keyframes ripple { 0% { opacity: .7;
    transform: scale(1); }
  100% { opacity: 0.6;
    transform: scale(1.4); } }

@keyframes spin { 0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); } }

@keyframes ripple--center { 0% { opacity: .7;
    transform: scale(1) translate(-50%, -50%);
    transform-origin: 50% 50%; }
  100% { opacity: 0.6;
    transform: scale(2.1) translate(-50%, -50%);
    transform-origin: 50% 50%; } }

@keyframes ripple--center-delay { 0% { opacity: 0;
    transform: translate(-50%, -50%) scale(1); }
  1% { opacity: .3;
    transform: translate(-50%, -50%) scale(1); }
  80% { opacity: 0.03;
    transform: translate(-50%, -50%) scale(1.9); }
  85% { opacity: 0;
    transform: translate(-50%, -50%) scale(1.9); }
  100% { opacity: 0;
    transform: translate(-50%, -50%) scale(2.1); } }

[data-ae], [data-st], [data-fade] { opacity: 0; will-change: opacity; }

.btn, .btn-i, .form__button { display: inline-block; white-space: nowrap; cursor: pointer; user-select: none; }

.btn, .btn-i, .form__button { padding: 0 64px; height: 58px; line-height: 58px; text-align: center; background-color: #FEB032; transition: background-color .3s; border-radius: 8px; }

@media (max-width: 1023px) { .btn, .btn-i, .form__button { padding: 0 48px; height: 50px; line-height: 50px; } }

@media (max-width: 767px) { .btn, .btn-i, .form__button { width: 100%; } }

.btn:hover, .btn-i:hover, .form__button:hover { background-color: rgba(254, 176, 50, 0.8); }

.btn-i { display: inline-flex; align-items: center; justify-content: center; }

.btn-i span { position: relative; display: block; transition: transform .3s; }

.btn-i:hover span { transform: translateX(0.75em); }

.btn-i:hover svg { opacity: 1; }

.btn-i svg { position: absolute; top: 50%; right: 100%; display: block; width: 1.2em; max-height: 1.5em; margin-right: 0.3em; transform: translateY(-50%); opacity: 0; transition: opacity .3s; }

.btn-i svg path { stroke: currentColor; }

.richtext *:first-child { margin-top: 0; }

.richtext *:last-child { margin-bottom: 0; }

.richtext h1 { margin: 1em 0; }

.richtext h2 { margin: 1em 0; }

.richtext h3 { margin: 1em 0; }

.richtext h5 { margin: 1em 0; }

.richtext h6 { margin: 1em 0; }

.richtext p { margin-top: 1em; margin-bottom: 1em; }

.richtext li { margin-bottom: 1em; }

.richtext li:last-child { margin-bottom: 0; }

.richtext b, .richtext strong { font-weight: bold; }

.richtext i, .richtext em { font-style: italic; }

.richtext img, .richtext video { margin: 1em auto; }

.richtext table { width: 100%; }

.footer { position: sticky; bottom: 0; left: 0; z-index: 1; background-color: #333; padding: 60px 0 40px; color: #fff; }

@media (max-width: 767px) { .footer { padding: 32px 0 32px; } }

.footer__logo-wrapper { flex-shrink: 0; width: 100%; max-width: 100%; padding-right: calc(var(--bs-gutter-x) / 2); padding-left: calc(var(--bs-gutter-x) / 2); margin-top: var(--bs-gutter-y); flex: 0 0 auto; width: 33.33333%; display: flex; align-items: flex-start; justify-content: center; user-select: none; }

@media (max-width: 1023px) { .footer__logo-wrapper { flex-shrink: 0; width: 100%; max-width: 100%; padding-right: calc(var(--bs-gutter-x) / 2); padding-left: calc(var(--bs-gutter-x) / 2); margin-top: var(--bs-gutter-y); flex: 0 0 auto; width: 25%; } }

@media (max-width: 767px) { .footer__logo-wrapper { flex-shrink: 0; width: 100%; max-width: 100%; padding-right: calc(var(--bs-gutter-x) / 2); padding-left: calc(var(--bs-gutter-x) / 2); margin-top: var(--bs-gutter-y); flex: 0 0 auto; width: 100%; margin-bottom: 24px; } }

.footer__logo { max-width: 80px; user-select: none; }

@media (max-width: 767px) { .footer__logo { max-width: 50px; } }

.footer__grid { --bs-gutter-x: 30px; --bs-gutter-y: 0; display: flex; flex-wrap: wrap; margin-top: calc(var(--bs-gutter-y) * -1); margin-right: calc(var(--bs-gutter-x) / -2); margin-left: calc(var(--bs-gutter-x) / -2); }

.footer__col { flex-shrink: 0; width: 100%; max-width: 100%; padding-right: calc(var(--bs-gutter-x) / 2); padding-left: calc(var(--bs-gutter-x) / 2); margin-top: var(--bs-gutter-y); flex: 0 0 auto; width: 33.33333%; }

@media (max-width: 1023px) { .footer__col { flex-shrink: 0; width: 100%; max-width: 100%; padding-right: calc(var(--bs-gutter-x) / 2); padding-left: calc(var(--bs-gutter-x) / 2); margin-top: var(--bs-gutter-y); flex: 0 0 auto; width: 37.5%; } }

@media (max-width: 767px) { .footer__col { flex-shrink: 0; width: 100%; max-width: 100%; padding-right: calc(var(--bs-gutter-x) / 2); padding-left: calc(var(--bs-gutter-x) / 2); margin-top: var(--bs-gutter-y); flex: 0 0 auto; width: 100%; margin-bottom: 40px; text-align: center; } }

.footer__col-title { margin-bottom: 12px; }

.footer__nav-list { margin-top: 20px; }

.footer__nav-item + .footer__nav-item { margin-top: 10px; }

.footer__nav-link { transition: color .3s; }

.footer__nav-link:hover { color: #FEB032; }

.footer__copy { font-size: 0.8em; margin-top: 32px; text-align: center; }

@media (max-width: 767px) { .footer__copy { margin-top: 16px; } }

.social { display: flex; align-items: center; user-select: none; }

@media (max-width: 767px) { .social { justify-content: center; } }

.social__item { user-select: none; }

.social__item + .social__item { margin-left: 16px; }

.social__link { display: block; transition: opacity .3s, transform .3s; }

.social__link:hover { opacity: 0.8; transform: scale(1.1); }

.social__icon { display: block; width: 24px; height: auto; pointer-events: none; }

@media (max-width: 1023px) { .social__icon { width: 32px; } }

main { position: relative; z-index: 2; background-color: #fff; }

.usp__title { margin-bottom: 32px; }

.usp__grid { --bs-gutter-x: 30px; --bs-gutter-y: 0; display: flex; flex-wrap: wrap; margin-top: calc(var(--bs-gutter-y) * -1); margin-right: calc(var(--bs-gutter-x) / -2); margin-left: calc(var(--bs-gutter-x) / -2); }

.usp__item { flex-shrink: 0; width: 100%; max-width: 100%; padding-right: calc(var(--bs-gutter-x) / 2); padding-left: calc(var(--bs-gutter-x) / 2); margin-top: var(--bs-gutter-y); flex: 0 0 auto; width: 33.33333%; text-align: center; }

@media (max-width: 767px) { .usp__item { flex-shrink: 0; width: 100%; max-width: 100%; padding-right: calc(var(--bs-gutter-x) / 2); padding-left: calc(var(--bs-gutter-x) / 2); margin-top: var(--bs-gutter-y); flex: 0 0 auto; width: 100%; }
  .usp__item + .usp__item { margin-top: 32px; } }

.usp__item-box { display: flex; align-items: center; justify-content: center; font-size: 80px; margin: 0 auto 16px; width: 1.1em; height: 1em; }

@media (max-width: 1511px) { .usp__item-box { font-size: 70px; } }

@media (max-width: 1199px) { .usp__item-box { font-size: 60px; } }

@media (max-width: 1023px) { .usp__item-box { font-size: 60px; } }

@media (max-width: 767px) { .usp__item-box { font-size: 56px; width: 1.1em; margin-bottom: 8px; } }

.usp__item-icon { display: block; width: 100%; height: 100%; user-select: none; object-fit: contain; }

.usp__item-text { font-family: "Prata", Georgia, Garamond, "Times New Roman", Times, serif; max-width: 450px; margin: 0 auto; }

.pgrid__title { margin-bottom: 32px; }

.pgrid__row { --bs-gutter-x: 30px; --bs-gutter-y: 0; display: flex; flex-wrap: wrap; margin-top: calc(var(--bs-gutter-y) * -1); margin-right: calc(var(--bs-gutter-x) / -2); margin-left: calc(var(--bs-gutter-x) / -2); }

@media (max-width: 1023px) { .pgrid__row { margin-bottom: -32px; } }

.pgrid__col { flex-shrink: 0; width: 100%; max-width: 100%; padding-right: calc(var(--bs-gutter-x) / 2); padding-left: calc(var(--bs-gutter-x) / 2); margin-top: var(--bs-gutter-y); flex: 0 0 auto; width: 25%; }

@media (max-width: 1023px) { .pgrid__col { flex-shrink: 0; width: 100%; max-width: 100%; padding-right: calc(var(--bs-gutter-x) / 2); padding-left: calc(var(--bs-gutter-x) / 2); margin-top: var(--bs-gutter-y); flex: 0 0 auto; width: 50%; margin-bottom: 32px; } }

.product-card__figure { aspect-ratio: 1; border-radius: 16px; margin-bottom: 16px; user-select: none; overflow: hidden; }

@media (max-width: 767px) { .product-card__figure { margin-bottom: 12px; } }

.product-card__img { width: 100%; height: 100%; object-fit: cover; }

.product-card__title { font-family: "Prata", Georgia, Garamond, "Times New Roman", Times, serif; margin-bottom: 12px; }

@media (max-width: 767px) { .product-card__title { margin-bottom: 8px; } }

.product-card__button-wrapper { margin-top: 16px; }

.modal { position: fixed; z-index: 100; top: 0; left: 0; display: flex; align-items: center; justify-content: center; width: 100%; height: 100%; opacity: 0; pointer-events: none; transition: opacity .3s, transform .3s; }

.modal--visible { opacity: 1; pointer-events: all; }

.modal__overlay { position: absolute; top: 0; left: 0; width: 100%; height: 100%; background-color: rgba(0, 0, 0, 0.6); z-index: 1; }

.modal__body { position: relative; z-index: 2; width: 500px; max-width: 95%; background-color: #fff; padding: 24px; border-radius: 16px; transition: opacity .3s; transform: translateY(40px) scale(0.96); transition: transform .3s; }

.modal--visible .modal__body { transform: translateY(0) scale(1); }

.modal__content { transition: opacity .3s; }

.modal--loading .modal__content { opacity: 0.4; pointer-events: none; user-select: none; }

.modal__loader { position: absolute; top: 50%; left: 50%; transform: translate(-50%, -50%); z-index: 2; user-select: none; display: none; pointer-events: none; }

.modal--loading .modal__loader { display: block; }

.modal__close { font-size: 24px; position: absolute; top: 16px; right: 16px; width: 1em; height: 1em; font-size: 24px; cursor: pointer; user-select: none; }

.modal__close:after, .modal__close:before { content: ''; position: absolute; top: 50%; left: 50%; width: 100%; height: 2px; background-color: currentColor; opacity: 0.85; }

.modal__close:after { transform: translate(-50%, -50%) rotate(45deg); }

.modal__close:before { transform: translate(-50%, -50%) rotate(-45deg); }

.modal__title { margin-bottom: 16px; }

.modal__text { line-height: 1.5; }

.modal__form { margin-top: 24px; }

.modal__thank { max-height: 0; opacity: 0; text-align: center; padding: 0 28px; transition: opacity .1s, margin 0.1s; user-select: none; pointer-events: none; }

.modal--thank .modal__thank { opacity: 1; max-height: 150px; margin-top: 16px; transition: opacity .5s, margin .3s; }

.form { display: flex; flex-direction: column; align-items: flex-start; }

.form__label { width: 100%; font-size: 0.8em; }

.form__input { width: 100%; display: block; margin: 0px 0 24px; padding: 4px 0px; border-bottom: 1px solid #FEB032; }

.form__input::placeholder { opacity: 0.8; }

.form__button-wrapper { display: flex; justify-content: center; width: 100%; }

.form__button { user-select: none; cursor: pointer; }

.loader { font-size: 48px; width: 1em; height: 1em; border-radius: 100%; overflow: hidden; border: 0.1em solid #FEB032; border-right-color: #fed798; animation: spin 3s infinite linear; }

.hero { position: relative; padding: 90px 0 48px; overflow: hidden; background-color: #fdf1e1; }

@media (max-width: 767px) { .hero { padding: 60px 0 32px; } }

.hero__logo { position: absolute; top: 16px; left: 50%; transform: translateX(-50%); display: block; width: 40px; height: auto; z-index: 1; user-select: none; }

@media (max-width: 767px) { .hero__logo { top: 12px; width: 36px; } }

.hero__grid { --bs-gutter-x: 30px; --bs-gutter-y: 0; display: flex; flex-wrap: wrap; margin-top: calc(var(--bs-gutter-y) * -1); margin-right: calc(var(--bs-gutter-x) / -2); margin-left: calc(var(--bs-gutter-x) / -2); align-items: center; }

.hero__content { flex-shrink: 0; width: 100%; max-width: 100%; padding-right: calc(var(--bs-gutter-x) / 2); padding-left: calc(var(--bs-gutter-x) / 2); margin-top: var(--bs-gutter-y); flex: 0 0 auto; width: 41.66667%; }

@media (max-width: 1023px) { .hero__content { flex-shrink: 0; width: 100%; max-width: 100%; padding-right: calc(var(--bs-gutter-x) / 2); padding-left: calc(var(--bs-gutter-x) / 2); margin-top: var(--bs-gutter-y); flex: 0 0 auto; width: 50%; } }

@media (max-width: 767px) { .hero__content { flex-shrink: 0; width: 100%; max-width: 100%; padding-right: calc(var(--bs-gutter-x) / 2); padding-left: calc(var(--bs-gutter-x) / 2); margin-top: var(--bs-gutter-y); flex: 0 0 auto; width: 100%; margin-left: 0; order: 2; margin-top: 24px; }
  [dir="rtl"] .hero__content { margin-right: 0; margin-left: initial; } }

.hero__title { position: relative; margin-bottom: 16px; line-height: 1.4; user-select: none; }

.hero__word-slider { display: inline-block; position: relative; z-index: 1; padding: 0 8px; margin-left: 0.1em; user-select: none; transform: rotate(-1deg); transform-origin: center; }

.hero__word-slider:after { content: ''; position: absolute; top: 50%; left: 50%; display: block; width: calc(100% + 16px); height: calc(100% + 0px); background-color: #FEB032; transform: translate(-50%, -50%); border-radius: 16px; transform-origin: center; z-index: -1; }

.hero__word-frame { display: inline-flex; position: relative; overflow: hidden; }

.hero__word-inner { opacity: 0; user-select: none; pointer-events: none; }

.hero__word-old { position: absolute; top: 50%; left: 50%; transform: translate(-50%, -50%); }

.hero__word-new { position: absolute; top: 50%; left: 50%; transform: translate(-50%, 50%); }

.hero__text { margin-bottom: 32px; }

.hero__figure { flex-shrink: 0; width: 100%; max-width: 100%; padding-right: calc(var(--bs-gutter-x) / 2); padding-left: calc(var(--bs-gutter-x) / 2); margin-top: var(--bs-gutter-y); flex: 0 0 auto; width: 33.33333%; margin-left: 8.33333%; }

[dir="rtl"] .hero__figure { margin-right: 8.33333%; margin-left: initial; }

@media (max-width: 1023px) { .hero__figure { flex-shrink: 0; width: 100%; max-width: 100%; padding-right: calc(var(--bs-gutter-x) / 2); padding-left: calc(var(--bs-gutter-x) / 2); margin-top: var(--bs-gutter-y); flex: 0 0 auto; width: 50%; margin-left: 0; }
  [dir="rtl"] .hero__figure { margin-right: 0; margin-left: initial; } }

@media (max-width: 767px) { .hero__figure { flex-shrink: 0; width: 100%; max-width: 100%; padding-right: calc(var(--bs-gutter-x) / 2); padding-left: calc(var(--bs-gutter-x) / 2); margin-top: var(--bs-gutter-y); flex: 0 0 auto; width: 100%; margin-left: 0; }
  [dir="rtl"] .hero__figure { margin-right: 0; margin-left: initial; } }

.hero__figure-inner { position: relative; border-radius: 50px; overflow: hidden; background: linear-gradient(62deg, #e4bb4000, #f8812b4f); user-select: none; }

.hero__img { display: block; width: 100%; }

.hero__figure-circle { position: absolute; top: 60%; left: 50%; width: 30%; border-radius: 100%; border: 5px solid #FEB032; animation: ripple--center-delay 5s ease-out infinite; opacity: 0; user-select: none; pointer-events: none; }

.hero__figure-circle:nth-of-type(2) { animation: ripple--center-delay 5s 2.5s ease-out infinite; }

.hero__figure-circle:after { content: ''; display: block; width: 50%; padding-top: 100%; }

.hero__aside { position: absolute; display: flex; font-family: "Prata", Georgia, Garamond, "Times New Roman", Times, serif; right: 0; top: 50%; font-size: 120px; transform: rotate(-90deg) translate(50%, -10%); transform-origin: right; color: #FEB032; user-select: none; }

@media (max-width: 1023px) { .hero__aside { display: none; } }

.hero__aside-letter { display: inline-block; }

.product__row { --bs-gutter-x: 30px; --bs-gutter-y: 0; display: flex; flex-wrap: wrap; margin-top: calc(var(--bs-gutter-y) * -1); margin-right: calc(var(--bs-gutter-x) / -2); margin-left: calc(var(--bs-gutter-x) / -2); align-items: flex-start; }

.product__row--reversed { flex-direction: row-reverse; }

.product__gallery { flex-shrink: 0; width: 100%; max-width: 100%; padding-right: calc(var(--bs-gutter-x) / 2); padding-left: calc(var(--bs-gutter-x) / 2); margin-top: var(--bs-gutter-y); flex: 0 0 auto; width: 50%; }

@media (max-width: 767px) { .product__gallery { flex-shrink: 0; width: 100%; max-width: 100%; padding-right: calc(var(--bs-gutter-x) / 2); padding-left: calc(var(--bs-gutter-x) / 2); margin-top: var(--bs-gutter-y); flex: 0 0 auto; width: 100%; gap: 8px; order: 2; } }

.product__gallery + .product__gallery { margin-top: 16px; }

@media (max-width: 767px) { .product__gallery + .product__gallery { margin-top: 8px; } }

.product__gallery-grid { display: grid; grid-template-columns: repeat(3, 1fr); grid-template-rows: repeat(2, 1fr); gap: 16px; }

.product__gallery-grid + .product__gallery-grid { margin-top: 16px; }

@media (max-width: 767px) { .product__gallery-grid + .product__gallery-grid { margin-top: 8px; } }

.product__col-wide { position: relative; z-index: 2; }

.product__figure { position: relative; display: block; overflow: hidden; cursor: pointer; border-radius: 10px; }

.bp-html .product__figure { border-radius: 0; height: 100%; pointer-events: none; }

.product__figure:nth-child(1) { grid-column: span 2; grid-row: span 2; }

.product__gallery-grid--reversed .product__figure:nth-child(1) { grid-column: span 1; grid-row: span 1; }

.product__gallery-grid--reversed .product__figure:nth-child(2) { grid-column: span 2; grid-row: span 2; }

.product__figure:after { content: ''; position: absolute; top: 0; left: 0; display: block; width: 100%; height: 100%; user-select: none; background-color: #000; z-index: 1; border-radius: inherit; }

@media (max-width: 767px) { .product__figure:after { display: none; } }

.product__figure:hover svg { opacity: 1; transform: translate(-50%, -50%) scale(1); }

.product__figure svg { position: absolute; top: 50%; left: 50%; display: block; transform: translate(-50%, -50%) scale(0.9); width: 60px; height: auto; user-select: none; opacity: 0; transition: opacity	.3s, transform .3s; z-index: 3; }

@media (max-width: 1023px) { .product__figure svg { width: 48px; } }

@media (max-width: 767px) { .product__figure svg { width: 40px; } }

.product__figure svg path { fill: #fff; }

.bp-html .product__figure svg { display: none; user-select: none; pointer-events: none; }

.product__img { position: relative; display: block; width: 100%; height: 100%; object-fit: cover; user-select: none; transition: opacity .3s, transform .3s; transform: scale(1.0); will-change: transform; border-radius: inherit; z-index: 2; }

.product__figure:hover .product__img { opacity: 0.75; transform: scale(1.03); }

.bp-html .product__figure:hover .product__img { opacity: 1; transform: scale(1); }

.product__col-narrow .product__figure + .product__figure { margin-top: auto; }

.product__content { flex-shrink: 0; width: 100%; max-width: 100%; padding-right: calc(var(--bs-gutter-x) / 2); padding-left: calc(var(--bs-gutter-x) / 2); margin-top: var(--bs-gutter-y); flex: 0 0 auto; width: 50%; position: sticky; top: 100px; }

@media (max-width: 767px) { .product__content { flex-shrink: 0; width: 100%; max-width: 100%; padding-right: calc(var(--bs-gutter-x) / 2); padding-left: calc(var(--bs-gutter-x) / 2); margin-top: var(--bs-gutter-y); flex: 0 0 auto; width: 100%; position: static; margin-bottom: 32px; } }

.product__title-wrapper { position: relative; margin-bottom: 24px; display: inline-block; }

.product__title-overlay { position: absolute; top: 0; left: 0; width: 100%; height: 110%; color: #FEB032; user-select: none; pointer-events: none; clip-path: polygon(0% 0%, 0% 0%, 0% 100%, 0% 100%); }

@media (max-width: 767px) { .product__title-overlay { display: none; } }

.product__text p + p { margin-top: 0.8em; }

.product__text strong { font-weight: 600; }

.product__icon { display: inline-block; transform: translateY(0.1em); user-select: none; }

.product__icon img { display: block; width: 0.9em; height: auto; margin-bottom: 0.05em; }

.product__icon svg { display: block; width: 1em; height: auto; }

.product__icon svg path { fill: #FEB032; }

.product__btn-wrapper { margin-top: 24px; }

.bp-lock body { overflow: unset !important; }

.reviews { background-color: #fdf1e1; overflow: hidden; }

.reviews__title { margin-bottom: 32px; }

.reviews__card { display: flex; flex-direction: column; align-items: center; text-align: center; background-color: #fff; border-radius: 16px; padding: 24px; }

@media (max-width: 767px) { .reviews__card { padding: 16px; } }

.reviews__card-frame { width: 64px; height: 64px; border-radius: 100px; overflow: hidden; margin-bottom: 16px; user-select: none; }

.reviews__card-image { width: 100%; height: 100%; object-fit: cover; pointer-events: none; }

.reviews__card-text { max-width: 450px; margin: 0 auto 12px; font-style: italic; }

@media (max-width: 767px) { .reviews .splide { padding: 0 32px; } }

.reviews .splide__list { display: flex; }

.reviews .splide__slide { width: 25%; flex-shrink: 0; padding: 0 24px; }

@media (max-width: 1511px) { .reviews .splide__slide { width: 33.3333%; padding: 0 16px; } }

@media (max-width: 1199px) { .reviews .splide__slide { padding: 0 12px; } }

@media (max-width: 1023px) { .reviews .splide__slide { width: 50%; } }

@media (max-width: 767px) { .reviews .splide__slide { width: 100%; } }

.reviews .splide__pagination { display: flex; align-items: center; justify-content: center; margin-top: 32px; user-select: none; list-style: none; }

@media (max-width: 1023px) { .reviews .splide__pagination { margin-top: 24px; } }

.reviews .splide__pagination__page { position: relative; display: block; font-size: 10px; width: 1em; height: 1em; margin: 0 0.4em; border-radius: 100%; background-color: #FEB032; opacity: 0.6; cursor: pointer; }

.reviews .splide__pagination__page.is-active { opacity: 1; }

.reviews .splide__pagination__page:after { content: ''; position: absolute; top: 50%; left: 50%; display: block; width: 160%; height: 160%; transform: translate(-50%, -50%); user-select: none; }

.reviews .splide__sr { display: none !important; }

@keyframes bp-fadein { from { opacity: 0.01; }
  to { opacity: 1; } }

@keyframes bp-bar { from { transform: translateX(-100%); }
  to { transform: translateX(0); } }

@keyframes bp-o { from { transform: rotate(0deg); }
  to { transform: rotate(360deg); } }

.bp-wrap { top: 0; left: 0; width: 100%; height: 100%; position: fixed; z-index: 999; /* If you're mounting on a portion of the screen and need visible overflow on .bp-wrap, change contain to "layout" on that instance */ contain: strict; touch-action: none; -webkit-tap-highlight-color: transparent; }

.bp-wrap > div:first-child { position: absolute; top: 0; left: 0; width: 100%; height: 100%; background: rgba(0, 0, 0, 0.75); animation: bp-fadein 0.48s cubic-bezier(0.215, 0.61, 0.355, 1); }

.bp-vid audio { position: absolute; left: 14px; width: calc(100% - 28px); bottom: 14px; height: 50px; }

.bp-inner { top: 0; left: 0; width: 100%; height: 100%; position: absolute; display: flex; }

.bp-html { display: contents; }

.bp-html > :first-child { margin: auto; }

.bp-img-wrap { top: 0; left: 0; width: 100%; height: 100%; position: absolute; contain: strict; }

.bp-img-wrap .bp-canzoom { cursor: zoom-in; }

.bp-img-wrap .bp-drag { cursor: grabbing; }

.bp-close { contain: layout size; }

.bp-img { position: absolute; top: 50%; left: 50%; user-select: none; background-size: 100% 100%; }

.bp-img img, .bp-img div { position: absolute; top: 0; left: 0; width: 100%; height: 100%; }

.bp-img .bp-o { display: none; }

.bp-zoomed .bp-img:not(.bp-drag) { cursor: grab; }

.bp-zoomed .bp-cap { opacity: 0; animation: none !important; }

.bp-zoomed.bp-small .bp-controls { opacity: 0; }

.bp-zoomed.bp-small .bp-controls button { pointer-events: none; }

.bp-controls { position: absolute; top: 0; left: 0; width: 100%; height: 100%; pointer-events: none; text-align: left; transition: opacity 0.3s; animation: bp-fadein 0.3s; }

.bp-controls button { pointer-events: auto; cursor: pointer; position: absolute; border: 0; background: rgba(0, 0, 0, 0.15); opacity: 0.9; transition: all 0.1s; contain: content; }

.bp-controls button:hover { background-color: rgba(0, 0, 0, 0.2); opacity: 1; }

.bp-controls svg { fill: #fff; }

.bp-count { position: absolute; color: rgba(255, 255, 255, 0.9); line-height: 1; margin: 16px; height: 50px; width: 100px; }

.bp-prev, .bp-next { top: 50%; right: 0; margin-top: -32px; height: 64px; width: 58px; border-radius: 3px 0 0 3px; }

.bp-prev:hover:before, .bp-next:hover:before { transform: translateX(-2px); }

.bp-prev:before, .bp-next:before { content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='%23fff'%3E%3Cpath d='M8.59 16.34l4.58-4.59-4.58-4.59L10 5.75l6 6-6 6z'/%3E%3C/svg%3E"); position: absolute; left: 7px; top: 9px; width: 46px; transition: all 0.2s; }

.bp-prev { right: auto; left: 0; transform: scalex(-1); }

.bp-x { top: 0; right: 0; height: 55px; width: 58px; border-radius: 0 0 0 3px; }

.bp-x:before { content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 32 32' fill='%23fff'%3E%3Cpath d='M24 10l-2-2-6 6-6-6-2 2 6 6-6 6 2 2 6-6 6 6 2-2-6-6z'/%3E%3C/svg%3E"); position: absolute; width: 37px; top: 8px; right: 10px; }

.bp-if, .bp-vid { position: relative; margin: auto; background: #000; background-size: 100% 100%; }

.bp-if iframe, .bp-if video, .bp-if div, .bp-vid iframe, .bp-vid video, .bp-vid div { top: 0; left: 0; width: 100%; height: 100%; position: absolute; border: 0; }

.bp-load { display: flex; background-size: 100% 100%; overflow: hidden; z-index: 1; }

.bp-bar { position: absolute; top: 0; left: 0; height: 3px; width: 100%; transform: translateX(-100%); background: rgba(255, 255, 255, 0.9); border-radius: 0 3px 3px 0; animation: bp-bar 4s both; }

.bp-o, .bp-o:after { border-radius: 50%; width: 90px; height: 90px; }

.bp-o { margin: auto; border: 10px solid rgba(255, 255, 255, 0.2); border-left-color: rgba(255, 255, 255, 0.9); animation: bp-o 1s infinite linear; }

.bp-cap { position: absolute; bottom: 2%; background: rgba(9, 9, 9, 0.8); color: rgba(255, 255, 255, 0.9); border-radius: 4px; max-width: 95%; line-height: 1.3; padding: 0.6em 1.2em; left: 50%; transform: translateX(-50%); width: fit-content; width: -moz-fit-content; display: table; transition: opacity 0.3s; animation: bp-fadein 0.2s; }

.bp-cap a { color: inherit; }

.bp-inline { position: absolute; }

.bp-lock { overflow-y: hidden; }

.bp-lock body { overflow: scroll; }

.bp-noclose .bp-x { display: none; }

.bp-noclose:not(.bp-zoomed) { touch-action: pan-y; }

.bp-noclose:not(.bp-zoomed) .bp-img-wrap { cursor: zoom-in; }

@media (prefers-reduced-motion) { .bp-wrap * { animation-duration: 0s !important; } }

@media (max-width: 500px) { .bp-x { height: 47px; width: 47px; }
  .bp-x:before { width: 34px; top: 6px; right: 6px; }
  .bp-prev, .bp-next { margin-top: -27px; height: 54px; width: 45px; }
  .bp-prev:before, .bp-next:before { top: 7px; left: 2px; width: 43px; }
  .bp-o, .bp-o:after { border-width: 6px; width: 60px; height: 60px; }
  .bp-count { margin: 12px 10px; } }
