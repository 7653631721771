//include third-party fonts

@mixin font-face($font-name, $file-name, $font-weight, $font-style) {
	@font-face {
		font-family: quote($font-name);
		font-style: $font-style;
		font-weight: $font-weight;
		src: url("../fonts/"+ $file-name + ".woff2") format("woff2"),
			url("../fonts/"+ $file-name + ".woff") format("woff");
		font-display: swap;
	}
}

@include font-face("Prata", Prata-Regular, 400, normal);
@include font-face("NotoSans", NotoSans-Regular, 400, normal);
@include font-face("NotoSans", NotoSans-Italic, 400, italic);

