*,
*::before,
*::after {
	box-sizing: border-box;
}

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
	padding: 0;
	margin: 0;
	font: inherit;
	font-size: 100%;
	vertical-align: baseline;
	border: 0;
	outline: none;
	-webkit-tap-highlight-color: transparent;
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
	display: block;
}

body {
	line-height: 1;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

ol,
ul {
	list-style: none;
}

blockquote,
q {
	quotes: none;
}

blockquote {

	&::before,
	&::after {
		content: "";
		content: none;
	}
}

q {

	&::before,
	&::after {
		content: "";
		content: none;
	}
}

table {
	border-spacing: 0;
	border-collapse: collapse;
}

button {
	width: auto;
	padding: 0;
	margin: 0;
	overflow: visible;
	font: inherit;

	/* Normalize `line-height`. Cannot be changed from `normal` in Firefox 4+. */
	line-height: normal;

	/* inherit font & color from ancestor */
	color: inherit;
	background: transparent;
	border: none;
	outline: none;

	/* Corrects font smoothing for webkit */
	-webkit-font-smoothing: inherit;
	-moz-osx-font-smoothing: inherit;

	/* Corrects inability to style clickable `input` types in iOS */
	appearance: none;

	/* Remove excess padding and border in Firefox 4+ */

	&::-moz-focus-inner {
		padding: 0;
		border: 0;
	}
}

a {
	color: inherit;
	text-decoration: none;
}

input,
select,
textarea,
label {
	display: inline-block;
	padding: 0;
	margin: 0;
	font-family: inherit;
	font-size: inherit;
	line-height: inherit;
	white-space: normal;
	vertical-align: middle;
	background: none;
	border: 0;
	border: none;
	border-radius: 0;
	box-shadow: none;
	box-shadow: none;
	box-shadow: none;
	appearance: none;

	&::placeholder {
		font-family: inherit;
		font-size: inherit;
		color: inherit;
	}

	&:focus {
		outline: 0;
	}
}
