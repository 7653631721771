.footer {
	position: sticky;
	bottom: 0;
	left: 0;
	z-index: 1;

	// height: 400px;
	background-color: #333;
	padding: 60px 0 40px;
	color: #fff;

	@include lg {
		
	}
	@include md {
		
	}
	@include sm {
		
	}
	@include xs {
		padding: 32px 0 32px;
	}

	&__container {
	}

	&__logo-wrapper {
		@include col(4);

		display: flex;
		// align-items: center;
		align-items: flex-start;
		justify-content: center;
		user-select: none;

		@include lg {
			
		}
		@include md {
			
		}
		@include sm {
			@include col(3);
		}
		@include xs {
			@include col(12);

			margin-bottom: 24px;
		}
	}

	&__logo {
		max-width: 80px;
		user-select: none;

		@include lg {
			
		}
		@include md {
			
		}
		@include sm {
			
		}
		@include xs {
			max-width: 50px;
		}
	}

	&__grid {
		@include row();
	}

	&__col {
		@include col(4);

		@include lg {
			
		}
		@include md {
			
		}
		@include sm {
			@include col(4.5);
		}
		@include xs {
			@include col(12);

			margin-bottom: 40px;
			text-align: center;
		}
	}

	&__col-title {
		@extend %h4;

		margin-bottom: 12px;
	}

	&__col-subtitle {
		// margin-bottom: 12px;
	}

	&__nav-list {
		margin-top: 20px;
	}

	&__nav-item {

		&+& {
			margin-top: 10px;
		}
	}

	&__nav-link {
		transition: color .3s;

		&:hover {
			color: $c-accent;
		}
	}

	&__social {
	}

	&__copy {
		font-size: 0.8em;
		margin-top: 32px;
		text-align: center;

		@include lg {
			
		}
		@include md {
			
		}
		@include sm {
			
		}
		@include xs {
			margin-top: 16px;
		}
	}
}



.social {
	display: flex;
	align-items: center;
	user-select: none;

	@include lg {
		
	}
	@include md {
		
	}
	@include sm {
		
	}
	@include xs {
		justify-content: center;
	}

	&__item {
		user-select: none;

		&+& {
			margin-left: 16px;
		}
	}

	&__link {
		display: block;
		transition: opacity .3s, transform .3s;

		&:hover {
			opacity: 0.8;
			transform: scale(1.1);
		}
	}

	&__icon {
		display: block;
		width: 24px;
		height: auto;
		pointer-events: none;

		@include lg {
			
		}
		@include md {
			
		}
		@include sm {
			width: 32px;
		}
		@include xs {
			// width: 45px;
		}
	}
}


main {
	position: relative;
	z-index: 2;
	background-color: #fff;
}


