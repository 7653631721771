html {
	font-family: $font-default;
	line-height: 1.2;
	scroll-behavior: smooth;
	
	// @extend %text-body;
	// color: $c-choco-100;
	// background-color: $c-oyster;
}
body {
	// @extend %text-body;
	// height: 100%;
	// -ms-overflow-style: none;
	// scrollbar-width: none;
	
	
	@include selection {
		color: #fff;
		background: $c-accent;
	}

	// &::-webkit-scrollbar {
	// 	display: none;
	// 	-webkit-appearance: none;
	// 	width: 0;
	// 	height: 0;
	// }
}



%section-padding {
	padding: 80px 0;

	@include lg {
		padding: 72px 0;
	}
	@include md {
		
	}
	@include sm {
		// padding: 72px 0;
	}
	@include xs {
		padding: 40px 0;
	}
}