.usp {
	@extend %section-padding;

	&__container {
	}
	&__title {
		margin-bottom: 32px;
	}

	&__grid {
		@include row;
	}

	&__item {
		@include col(4);
		text-align: center;

		@include lg {
			
		}
		@include md {
			
		}
		@include sm {
			
		}
		@include xs {
			@include col(12);

			&+& {
				margin-top: 32px;
			}
		}
	}

	&__item-box {
		display: flex;
		align-items: center;
		justify-content: center;
		font-size: 80px;
		margin: 0 auto 16px;
		width: 1.1em;
		height: 1em;

		@include lg {
			font-size: 70px;
		}
		@include md {
			font-size: 60px;
		}
		@include sm {
			font-size: 60px;
		}
		@include xs {
			font-size: 56px;
			width: 1.1em;
			margin-bottom: 8px;
		}
	}

	&__item-icon {
		display: block;
		width: 100%;
		height: 100%;
		user-select: none;
		object-fit: contain;
	}

	&__item-text {
		// @extend %text-mid;
		@extend %text-normal;
		font-family: $font-title;

		max-width: 450px;
		margin: 0 auto;
	}
}
