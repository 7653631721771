%container {
	width: 100%;
	max-width: calc(100% - 96px);
	margin: 0 auto;
	
	@include lg {
		max-width: calc(100% - 64px);
	}
	@include md {
		max-width: calc(100% - 48px);
	}
	@include sm {
		max-width: calc(100% - 64px);
	}
	@include xs {
		max-width: calc(100% - 40px);
	}
}


.container {
	@extend %container;
}

.row {
	@include row();
}
