@keyframes fadeIn {
	0% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}


@keyframes ripple {
	0% {
		opacity: .7;
		transform: scale(1);
	}
	100% {
		opacity: 0.6;
		transform: scale(1.4);
	}
}

@keyframes spin {
	0% {
		transform: rotate(0deg);
	}
	100% {
		transform: rotate(360deg);
	}
}



@keyframes ripple--center {
	0% {
		opacity: .7;
		transform: scale(1) translate(-50%, -50%);
		transform-origin: 50% 50%;
	}
	100% {
		opacity: 0.6;
		transform: scale(2.1) translate(-50%, -50%);
		transform-origin: 50% 50%;
	}
}

@keyframes ripple--center-delay {
	0% {
		opacity: 0;
		transform: translate(-50%,-50%) scale(1);
		// transform-origin: 50% 50%;
	}
	1% {
		opacity: .3;
		transform: translate(-50%,-50%) scale(1);
		// transform-origin: 50% 50%;
	}
	80% {
		opacity: 0.03;
		transform: translate(-50%,-50%) scale(1.9);
		// transform-origin: 50% 50%;
	}
	85% {
		opacity: 0;
		transform: translate(-50%,-50%) scale(1.9);
		// transform-origin: 50% 50%;
	}
	100% {
		opacity: 0;
		transform: translate(-50%,-50%) scale(2.1);
		// transform-origin: 50% 50%;
	}
}


// scroll reveal
[data-ae],
[data-st],
[data-fade] {
	opacity: 0;
	will-change: opacity;
}

