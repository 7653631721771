.pgrid {
	@extend %section-padding;
	
	$gap-v: 32px;

	// background-color: #ddd;

	&__container {
	}

	&__title {
		margin-bottom: 32px;
	}

	&__row {

		@include row;

		@include lg {
			
		}
		@include md {
			
		}
		@include sm {
			margin-bottom: -1 * $gap-v;
		}
		@include xs {
			
		}
	}

	&__col {
		@include col(3);

		@include lg {
			
		}
		@include md {
			
		}
		@include sm {
			@include col(6);

			margin-bottom: $gap-v;
		}
		@include xs {
			
		}
	}

	&__card {
	}
}

.product-card {

	&__figure {
		aspect-ratio: 1;
		
		border-radius: 16px;
		margin-bottom: 16px;
		user-select: none;
		overflow: hidden;

		@include lg {
			
		}
		@include md {
			
		}
		@include sm {
			
		}
		@include xs {
			margin-bottom: 12px;
		}
	}

	&__img {
		width: 100%;
		height: 100%;
		object-fit: cover;
	}

	&__title {
		@extend %text-mid;
		font-family: $font-title;

		margin-bottom: 12px;

		@include lg {
			
		}
		@include md {
			
		}
		@include sm {
			
		}
		@include xs {
			margin-bottom: 8px;
		}
	}

	&__price {
		@extend %text-normal;

		// color: $c-accent;
		// font-weight: bold;
	}

	&__button-wrapper {
		margin-top: 16px;
	}

	&__button {
	}
}
.hero {

	&__btn {
	}
}
.btn-i {
}
