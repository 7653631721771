.product {
	@extend %section-padding;

	&__container {

	}

	&__row {
		@include row;

		// align-items: center;
		align-items: flex-start;

		&--reversed {
			flex-direction: row-reverse;
			// background-color: red;
		}
	}

	&__gallery {
		@include col(6);

		@include lg {
			
		}
		@include md {
			
		}
		@include sm {
			
		}
		@include xs {
			@include col(12);

			gap: 8px;
			// margin-top: 32px;
			order: 2;
		}

		&+& {
			margin-top: 16px;

			@include lg {
				
			}
			@include md {
				
			}
			@include sm {
				
			}
			@include xs {
				margin-top: 8px;
			}
		}
	}

	&__gallery-grid {
		display: grid;
		grid-template-columns: repeat(3, 1fr);
		grid-template-rows: repeat(2, 1fr);
		gap: 16px;
		

		&+& {
			margin-top: 16px;

			@include lg {
				
			}
			@include md {
				
			}
			@include sm {
				
			}
			@include xs {
				margin-top: 8px;
			}
		}
	}

	&__col-wide {
		// @include col(4, 6);
		
		position: relative;
		z-index: 2;
	}

	&__figure {
		position: relative;
		display: block;
		// border-radius: 10px;
		overflow: hidden;
		// background-color: #000;
		cursor: pointer;
		border-radius: 10px;

		.bp-html & {
			border-radius: 0;
			height: 100%;
			pointer-events: none;
		}

		&:nth-child(1) {
			grid-column: span 2;
			grid-row: span 2;
			// opacity: 0.1;

			.product__gallery-grid--reversed & {
				grid-column: span 1;
				grid-row: span 1;
			}
		}

		&:nth-child(2) {

			.product__gallery-grid--reversed & {
				grid-column: span 2;
				grid-row: span 2;
			}
		}

		&:after {
			content: '';
			position: absolute;
			top: 0;
			left: 0;
			display: block;
			width: 100%;
			height: 100%;
			user-select: none;
			background-color: #000;
			z-index: 1;
			// border-radius: 10px;
			border-radius: inherit;

			// opacity: 0;

			@include lg {
				
			}
			@include md {
				
			}
			@include sm {
				
			}
			@include xs {
				display: none;
			}
		}

		&:hover {
			svg {
				opacity: 1;
				transform: translate(-50%, -50%) scale(1);
			}
		}

		svg {
			position: absolute;
			top: 50%;
			left: 50%;
			display: block;
			transform: translate(-50%, -50%) scale(0.9);
			width: 60px;
			height: auto;
			user-select: none;
			opacity: 0;
			transition: opacity	.3s, transform .3s;
			z-index: 3;

			@include lg {
				
			}
			@include md {
				
			}
			@include sm {
				width: 48px;
			}
			@include xs {
				width: 40px;
			}

			path {
				fill: $c-white;
			}

			.bp-html & {
				display: none;
				user-select: none;
				pointer-events: none;
			}
		}
	}

	&__img {
		position: relative;
		display: block;
		width: 100%;
		height: 100%;
		object-fit: cover;
		user-select: none;
		transition: opacity .3s, transform .3s;
		transform: scale(1.000001);
		will-change: transform;
		// border-radius: 10px;
		border-radius: inherit;
		z-index: 2;
		

		.product__figure:hover & {
			opacity: 0.75;
			transform: scale(1.03);

			.bp-html & {
				opacity: 1;
				transform: scale(1);
				
			}
		}
	}

	&__col-narrow {
		// @include col(2, 6);

		// display: flex;
		// flex-direction: column;

		// .product__figure {
		// 	margin-bottom: 15px;
		// }
		
		// .product__figure {
		// 	width: 100%;
		// }
		.product__figure + .product__figure {
			margin-top: auto;
			// opacity: 0.5;
		}
		
	}



	&__content {
		@include col(6);

		position: sticky;
		top: 100px;

		@include lg {
			
		}
		@include md {
			
		}
		@include sm {
			
		}
		@include xs {
			@include col(12);

			position: static;
			margin-bottom: 32px;
		}
	}
	&__title-wrapper {
		position: relative;
		margin-bottom: 24px;
		display: inline-block;
	}
	&__title {
		@extend %h2;
	}
	&__title-overlay {
		@extend %h2;

		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 110%;
		color: $c-accent;
		user-select: none;
		pointer-events: none;

		// clip-path: polygon(0% 0%, 25% 0, 23% 100%, 0% 100%);
		clip-path: polygon(0% 0%, 0% 0%, 0% 100%, 0% 100%);

		@include lg {
			
		}
		@include md {
			
		}
		@include sm {
			
		}
		@include xs {
			display: none;
		}
	}
	

	&__text {
		@extend %text-mid;


		p + p {
			margin-top: 0.8em;
		}

		strong {
			font-weight: 600;
		}
		// p {
		// 	&+& {
		// 		margin-top: 0.5em;
		// 	}
		// }
	}

	&__icon {
		display: inline-block;
		transform: translateY(0.1em);
		user-select: none;

		img {
			display: block;
			width: 0.9em;
			height: auto;
			margin-bottom: 0.05em;
		}
		svg {
			display: block;
			width: 1em;
			height: auto;

			path {
				fill: $c-accent;
			}
		}
	}

	&__btn-wrapper {
		margin-top: 24px;
	}

	&__btn {
	}
}


.bp-html {
	.product__img {
		// width: 100%;
		// height: 100%;
		// object-fit: contain;
	}
}


.bp-lock body {
	overflow: unset !important;
}