.reviews {
	@extend %section-padding;

	background-color: $c-accent--light;
	overflow: hidden;

	&__container {

	}

	&__title {
		margin-bottom: 32px;
	}

	&__slider {
	}

	&__card {
		display: flex;
		flex-direction: column;
		align-items: center;
		text-align: center;

		background-color: #fff;
		border-radius: 16px;
		padding: 24px;

		@include lg {
			
		}
		@include md {
			
		}
		@include sm {
			
		}
		@include xs {
			padding: 16px;
		}
	}

	&__card-frame {
		width: 64px;
		height: 64px;
		border-radius: 100px;
		overflow: hidden;
		margin-bottom: 16px;
		user-select: none;
	}

	&__card-image {
		width: 100%;
		height: 100%;
		object-fit: cover;
		pointer-events: none;
	}

	&__card-text {
		@extend %text-mid;

		max-width: 450px;
		margin: 0 auto 12px;
		font-style: italic;
	}

	&__card-name {
		@extend %text-small;
	}

	.splide {
		@include lg {
			
		}
		@include md {
			
		}
		@include sm {
			
		}
		@include xs {
			padding: 0 32px;
		}

		&__track {

		}

		&__list {
			display: flex;
		}

		&__slide {
			width: 25%;
			flex-shrink: 0;

			padding: 0 24px;

			@include lg {
				width: 33.3333%;
				padding: 0 16px;
			}
			@include md {
				padding: 0 12px;
			}
			@include sm {
				width: 50%;
			}
			@include xs {
				width: 100%;
			}
		}

		&__pagination {
			display: flex;
			align-items: center;
			justify-content: center;
			margin-top: 32px;
			user-select: none;
			list-style: none;

			@include lg {
				
			}
			@include md {
				
			}
			@include sm {
				margin-top: 24px;
			}
			@include xs {
				
			}
		}
		&__pagination__page {
			position: relative;
			display: block;
			font-size: 10px;
			width: 1em;
			height: 1em;
			margin: 0 0.4em;
			border-radius: 100%;
			background-color: $c-accent;
			opacity: 0.6;
			cursor: pointer;

			&.is-active {
				opacity: 1;
			}

			&:after {
				$size: 160%;
				content: '';
				position: absolute;
				top: 50%;
				left: 50%;
				display: block;
				// background-color: red;
				width: $size;
				height: $size;
				transform: translate(-50%, -50%);
				user-select: none;
			}
		}
		
		&__sr {
			display: none !important;
		}
	}
}


