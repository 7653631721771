.hero {
	position: relative;
	padding: 90px 0 48px;
	$t-ripple: 5s;
	$t-ripple-delay: $t-ripple / 2;
	overflow: hidden;
	background-color: $c-accent--light;

	@include lg {
		
	}
	@include md {
		
	}
	@include sm {
		
	}
	@include xs {
		padding: 60px 0 32px;
	}

	&__logo {
		position: absolute;
		top: 16px;
		left: 50%;
		transform: translateX(-50%);
		display: block;
		width: 40px;
		height: auto;
		z-index: 1;
		user-select: none;

		@include lg {
			
		}
		@include md {
			
		}
		@include sm {
			
		}
		@include xs {
			top: 12px;
			width: 36px;
		}
	}
	&__container {
	}

	&__grid {
		@include row();

		align-items: center;
	}

	&__content {
		@include col(5);

		@include lg {
			
		}
		@include md {
			
		}
		@include sm {
			@include col(6);
		}
		@include xs {
			@include col(12);
			@include offset(0);

			order: 2;
			margin-top: 24px;
		}

	}

	&__title {
		@extend %h1;

		position: relative;
		margin-bottom: 16px;
		line-height: 1.4;
		user-select: none;
	}

	&__word-slider {
		display: inline-block;
		position: relative;
		z-index: 1;
		padding: 0 8px;

		margin-left: 0.1em;
		user-select: none;

		transform: rotate(-1deg);
		transform-origin: center;

		&:after {
			content: '';
			position: absolute;
			top: 50%;
			left: 50%;

			display: block;
			width: calc(100% + 16px);
			height: calc(100% + 0px);
			background-color: $c-accent;
			// transform: translate(-50%, -50%) rotate(-0.5deg);
			transform: translate(-50%, -50%);
			border-radius: 16px;
			transform-origin: center;
			z-index: -1;
		}
	}

	&__word {
		// display: block;
	}

	&__word-frame {
		display: inline-flex;
		position: relative;
		overflow: hidden;
	}

	&__word-inner {
		opacity: 0;
		user-select: none;
		pointer-events: none;
	}
	&__word-old {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		// border: 1px solid green;
	}
	&__word-new {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, 50%);
		// border: 1px solid red;
		// opacity: 0;
	}
	
	
	

	&__text {
		@extend %text-normal;
		margin-bottom: 32px;
	}

	&__btn-wrapper {
	}

	&__btn {
	}




	&__figure {
		@include col(4);
		@include offset(1);

		@include lg {
			
		}
		@include md {
			
		}
		@include sm {
			@include col(6);
			@include offset(0);
		}
		@include xs {
			@include col(12);
			@include offset(0);
		}
	}

	&__figure-inner {
		position: relative;
		border-radius: 50px;
		overflow: hidden;
		// background-color: $c-sand;
		background: linear-gradient(62deg, #e4bb4000, #f8812b4f);
		user-select: none;
	}

	&__img {
		display: block;
		width: 100%;
	}

	&__figure-circle {
		position: absolute;
		top: 60%;
		left: 50%;
		width: 30%;
		// transform: translate(-50%, -50%);
		border-radius: 100%;
		border: 5px solid $c-accent;
		animation: ripple--center-delay $t-ripple ease-out infinite;
		opacity: 0;
		user-select: none;
		pointer-events: none;

		&:nth-of-type(2) {
			animation: ripple--center-delay $t-ripple $t-ripple-delay ease-out infinite;
		}

		&:after {
			content: '';
			display: block;
			width: 50%;
			padding-top: 100%;
		}
	}

	&__aside {
		position: absolute;
		// display: block;
		display: flex;
		font-family: $font-title;
		right: 0;
		top: 50%;
		font-size: 120px;
		transform: rotate(-90deg) translate(50%, -10%);
		transform-origin: right;
		color: $c-accent;
		user-select: none;

		@include lg {
			
		}
		@include md {
			
		}
		@include sm {
			// font-size: 70px;
			display: none;
		}
		@include xs {
			
		}
	}

	&__aside-letter {
		display: inline-block;
	}

}
